import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { Spinner, GoogleMapLibrary } from '../../../components';
import Select from 'react-select';
import _ from 'lodash';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import { photo, save, logo } from '../../../helpers/actions/users';
import { all as allProviders } from '../../../helpers/actions/providers';
import {
  roles,
  businessTypes,
  skills,
  specialities,
  clientOrigin
} from '../../../helpers/nomenclators';
import Gallery from '../../Images/Gallery';
import defaultAvatar from '../../../assets/img/default-avatar.png';

class UserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      user: {
        email: '',
        password: null,
        photoURL: '',
        displayName: '',
        roles: null,
        address: '',
        phone: {},
        client: {},
        pro: {},
        supervisor: {},
        admin: {},
        seller: {}
      },
      file: null,
      fileLogo: null,
      showGallery: false,
      providers: []
    };
  }

  componentWillMount() {
    this.setState(ps => ({ ...ps, loading: true }));
    let { userData, dispatch } = this.props;

    dispatch(allProviders({ limit: 100 }))
      .then(providers => {
        if (userData && userData._id) {
          //edit user
          if (userData && !userData.roles) userData.roles = [];
          if (userData && !userData.phone) userData.phone = {};
          if (userData && !userData.user) userData.user = {};
          if (userData && !userData.admin) userData.admin = {};
          if (userData && !userData.supervisor) userData.supervisor = {};
          if (userData && !userData.pro) userData.pro = {};
          if (userData && !userData.seller) userData.seller = {};
          if (userData && !userData.client) userData.client = {};

          this.setState({
            user: userData,
            providers: providers.data,
            loading: false
          });
        } else {
          //create user
          this.setState({ providers: providers.data, loading: false });
        }
      })
      .catch(() => this.setState({ loading: false }));
  }

  close() {
    this.setState({
      user: {
        email: '',
        password: null,
        photoURL: '',
        displayName: '',
        roles: [],
        address: '',
        phone: {},
        client: {},
        pro: {},
        supervisor: {},
        admin: {},
        seller: {}
      },
      displayNameValid: '',
      emailValid: '',
      rolesValid: '',
      addressValid: '',
      mobileValid: '',
      workValid: '',
      landlineValid: '',
      passwordValid: '',

      jobTitleValid: '',

      specialitiesValid: '',
      skillsValid: '',
      delivery_addressValid: '',
      descriptionValid: '',
      commercialNameValid: '',
      commercialStatusValid: '',
      siretValid: '',
      sirenValid: '',
      managerValid: '',

      providerValid: '',

      firstnameValid: '',
      lastnameValid: '',
      originValid: '',

      file: null,
      fileLogo: null,
      providers: []
    });
    this.props.onCancel();
  }

  handleImageChange(file) {
    let { user } = this.state;
    let reader = new FileReader();

    reader.onloadend = () => {
      user.photoURL = reader.result;
      this.setState(ps => ({ ...ps, user, file: file }));
    };
    reader.readAsDataURL(file);
  }

  handleClick() {
    let input = document.createElement('input');

    input.type = 'file';
    input.onchange = e => {
      e.preventDefault();
      this.handleImageChange(e.target.files[0]);
    };
    input.click();
  }

  handleLogoChange(file) {
    let { user } = this.state;
    let reader = new FileReader();

    reader.onloadend = () => {
      user.pro.logo = reader.result;
      this.setState(ps => ({ ...ps, user, fileLogo: file }));
    };
    reader.readAsDataURL(file);
  }

  handleClickLogo() {
    let input = document.createElement('input');

    input.type = 'file';
    input.onchange = e => {
      e.preventDefault();
      this.handleLogoChange(e.target.files[0]);
    };
    input.click();
  }

  onChangeUser(dataType, name, nameValid, value, valid) {
    let { user } = this.state;

    if (dataType) {
      user[dataType][name] = value;
    } else {
      user[name] = value;
    }

    this.setState({
      user,
      [nameValid]: valid ? 'has-success' : 'has-danger'
    });
  }

  onChangeRol(value) {
    let { user } = this.state;
    user.roles = value;

    if (!_.find(value, t => t === 'admin')) {
      user.admin = {};
    }
    if (!_.find(value, t => t === 'supervisor')) {
      user.supervisor = {};
    }
    if (!_.find(value, t => t === 'pro')) {
      user.pro = {};
    }
    if (!_.find(value, t => t === 'seller')) {
      user.seller = {};
    }
    if (!_.find(value, t => t === 'client')) {
      user.client = {};
    }

    this.setState({
      user,
      rolesValid: !!value.length ? 'has-success' : 'has-danger'
    });
  }

  validateUser() {
    let { user } = this.state;

    let displayName = this.displayName;
    let email = this.email;
    let address = this.address;
    let mobile = this.mobile;
    let work = this.work;
    let landline = this.landline;
    let password = this.password;

    let validAdmin = true,
      validSupervisor = true,
      validPro = true,
      validSeller = true,
      validClient = true;

    let newState = {
      [displayName.attributes.getNamedItem('namevalid').value]: displayName
        .validity.valid
        ? 'has-success'
        : 'has-danger',
      [email.attributes.getNamedItem('namevalid').value]: email.validity.valid
        ? 'has-success'
        : 'has-danger',
      rolesValid:
        user.roles && user.roles.length ? 'has-success' : 'has-danger',
      [address.attributes.getNamedItem('namevalid').value]: address.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      [mobile.attributes.getNamedItem('namevalid').value]: mobile.validity.valid
        ? 'has-success'
        : 'has-danger',
      [work.attributes.getNamedItem('namevalid').value]: work.validity.valid
        ? 'has-success'
        : 'has-danger',
      [landline.attributes.getNamedItem('namevalid').value]: landline.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      [password.attributes.getNamedItem('namevalid').value]: password.validity
        .valid
        ? 'has-success'
        : 'has-danger'
    };

    if (user.roles) {
      if (_.find(user.roles, t => t === 'supervisor')) {
        let jobTitle = this.jobTitle;

        newState = {
          ...newState,
          [jobTitle.attributes.getNamedItem('namevalid').value]: jobTitle
            .validity.valid
            ? 'has-success'
            : 'has-danger'
        };

        validSupervisor = jobTitle.validity.valid;
      }
      if (_.find(user.roles, t => t === 'pro')) {
        let delivery_address = this.delivery_address;
        let description = this.description;
        let commercialName = this.commercialName;
        let siret = this.siret;
        let siren = this.siren;
        let manager = this.manager;

        newState = {
          ...newState,
          specialitiesValid:
            user.pro && user.pro.specialities ? 'has-success' : 'has-success',
          skillsValid:
            user.pro && user.pro.skills ? 'has-success' : 'has-success',
          [delivery_address.attributes.getNamedItem('namevalid')
            .value]: delivery_address.validity.valid
            ? 'has-success'
            : 'has-danger',
          [description.attributes.getNamedItem('namevalid').value]: description
            .validity.valid
            ? 'has-success'
            : 'has-danger',
          [commercialName.attributes.getNamedItem('namevalid')
            .value]: commercialName.validity.valid
            ? 'has-success'
            : 'has-danger',
          commercialStatusValid: 'has-success',
          [siret.attributes.getNamedItem('namevalid').value]: siret.validity
            .valid
            ? 'has-success'
            : 'has-danger',
          [siren.attributes.getNamedItem('namevalid').value]: siren.validity
            .valid
            ? 'has-success'
            : 'has-danger',
          [manager.attributes.getNamedItem('namevalid').value]: manager.validity
            .valid
            ? 'has-success'
            : 'has-danger'
        };

        validPro =
          user.pro &&
          user.pro.specialities &&
          user.pro.skills &&
          delivery_address.validity.valid &&
          description.validity.valid &&
          commercialName.validity.valid &&
          siret.validity.valid &&
          siren.validity.valid &&
          manager.validity.valid;
      }
      if (_.find(user.roles, t => t === 'seller')) {
        newState = {
          ...newState,
          providerValid:
            user.seller && user.seller.provider ? 'has-success' : 'has-danger'
        };

        validSeller = user.seller && user.seller.provider;
      }
      if (_.find(user.roles, t => t === 'client')) {
        let firstname = this.firstname;
        let lastname = this.lastname;

        newState = {
          ...newState,
          originValid:
            user.client && user.client.origin ? 'has-success' : 'has-danger',
          [firstname.attributes.getNamedItem('namevalid').value]: firstname
            .validity.valid
            ? 'has-success'
            : 'has-danger',
          [lastname.attributes.getNamedItem('namevalid').value]: lastname
            .validity.valid
            ? 'has-success'
            : 'has-danger'
        };

        validClient =
          user.client &&
          user.client.origin &&
          firstname.validity.valid &&
          lastname.validity.valid;
      }
    }

    this.setState({ ...newState });

    return (
      displayName.validity.valid &&
      email.validity.valid &&
      user.roles.length &&
      address.validity.valid &&
      mobile.validity.valid &&
      work.validity.valid &&
      landline.validity.valid &&
      password.validity.valid &&
      validAdmin &&
      validSupervisor &&
      validPro &&
      validSeller &&
      validClient
    );
  }

  async onSaveUser() {
    if (this.validateUser()) {
      this.setState({ loading: true });

      let { user, file, fileLogo } = this.state,
        { dispatch } = this.props;

      if (user.address && user.address !== '') {
        await geocodeByAddress(user.address)
          .then(results => getLatLng(results[0]))
          .then(({ lat, lng }) => {
            user.lat = lat;
            user.lng = lng;
          })
          .catch(() => {
            user.lat = null;
            user.lng = null;
          });
      } else {
        user.lat = null;
        user.lng = null;
      }

      if (file) delete user.photoURL;
      if (fileLogo) delete user.pro.logo;

      dispatch(save(user))
        .then(userRes => {
          if (userRes._id && (file || fileLogo)) {
            let promises = [];

            if (file) promises.push(dispatch(photo(userRes._id, file)));

            if (fileLogo) promises.push(dispatch(logo(userRes._id, fileLogo)));

            Promise.all(promises)
              .then(() => this.props.onConfirm())
              .catch(() => this.props.onConfirm());
          } else {
            this.props.onConfirm();
          }
        })
        .catch(() => this.props.onCancel());
    }
  }

  render() {
    let { loading, user, showGallery, providers } = this.state,
      { t } = this.props;

    return (
      <Modal size="lg" isOpen={this.props.show} toggle={() => this.close()}>
        {loading ? <Spinner /> : null}
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.close()}
        >
          {user._id ? t('Edit User') : t('New User')}
        </ModalHeader>
        <ModalBody>
          {user ? (
            <Form className="form-horizontal" autoComplete={'off'}>
              {/* General Data */}
              <Row>
                <Col xs={12}>
                  <h6>
                    <Trans>Personal Information</Trans>
                  </h6>
                  <hr />
                </Col>
                <Col xs={12} md={4}>
                  <Col
                    xs={12}
                    className={'display-flex justify-content-center'}
                  >
                    <div
                      className="avatar-image"
                      style={{
                        backgroundImage: `url('${_.get(
                          user,
                          'photoURL',
                          defaultAvatar
                        )}')`
                      }}
                    />
                  </Col>
                  <Col xs={12} className={'justify-content-center text-center'}>
                    <Button
                      color={'info'}
                      round="true"
                      onClick={() => this.handleClick()}
                    >
                      <Trans>Change</Trans>
                    </Button>
                    <Button
                      color="info"
                      onClick={() => this.setState({ showGallery: true })}
                    >
                      <Trans>Gallery</Trans>
                    </Button>
                  </Col>
                </Col>
                <Col xs={12} md={8}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' +
                      this.state.displayNameValid
                    }
                  >
                    <Label>
                      <Trans>Display Name</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.displayName = node)}
                      namevalid="displayNameValid"
                      value={user.displayName || ''}
                      required="required"
                      onChange={event =>
                        this.onChangeUser(
                          null,
                          'displayName',
                          'displayNameValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.emailValid
                    }
                  >
                    <Label>
                      <Trans>Email</Trans>
                    </Label>
                    <Input
                      type="email"
                      innerRef={node => (this.email = node)}
                      namevalid="emailValid"
                      value={user.email || ''}
                      required="required"
                      onChange={event =>
                        this.onChangeUser(
                          null,
                          'email',
                          'emailValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.rolesValid
                    }
                  >
                    <Label>
                      <Trans>Roles</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={roles.map(c => ({
                        label: t(c),
                        value: c
                      }))}
                      multi={true}
                      closeOnSelect={true}
                      value={user.roles}
                      onChange={event =>
                        this.onChangeRol(event.map(e => e.value))
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.addressValid
                    }
                  >
                    <Label>
                      <Trans>Address</Trans>
                    </Label>
                    <GoogleMapLibrary>
                      <PlacesAutocomplete
                        value={user.address ? user.address : ''}
                        onChange={address =>
                          this.onChangeUser(
                            null,
                            'address',
                            'addressValid',
                            address,
                            true
                          )
                        }
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps
                        }) => (
                          <div>
                            <input
                              ref={node => (this.address = node)}
                              {...getInputProps({
                                className: 'form-control',
                                namevalid: 'addressValid'
                              })}
                            />
                            <ul
                              className={
                                'dropdown-menu inner ' +
                                (suggestions.length === 0 ? '' : 'show')
                              }
                            >
                              {suggestions.map(suggestion => (
                                <li {...getSuggestionItemProps(suggestion)}>
                                  <a href="/#" className="dropdown-item">
                                    <span className="text">
                                      {suggestion.description}
                                    </span>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </GoogleMapLibrary>
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.mobileValid
                    }
                  >
                    <Label>
                      <Trans>Mobile</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.mobile = node)}
                      namevalid="mobileValid"
                      value={user.phone.mobile || ''}
                      onChange={event =>
                        this.onChangeUser(
                          'phone',
                          'mobile',
                          'mobileValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.workValid
                    }
                  >
                    <Label>
                      <Trans>Work</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.work = node)}
                      namevalid="workValid"
                      value={user.phone.work || ''}
                      onChange={event =>
                        this.onChangeUser(
                          'phone',
                          'work',
                          'workValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.landlineValid
                    }
                  >
                    <Label>
                      <Trans>Landline</Trans>
                    </Label>
                    <Input
                      id={'landline'}
                      autoComplete={'off'}
                      name={'landline'}
                      type="text"
                      innerRef={node => (this.landline = node)}
                      namevalid="landlineValid"
                      value={user.phone.landline || ''}
                      onChange={event =>
                        this.onChangeUser(
                          'phone',
                          'landline',
                          'landlineValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.passwordValid
                    }
                  >
                    <Label>
                      <Trans>Password</Trans>
                    </Label>
                    <Input
                      type="password"
                      autoComplete={'new-password'}
                      name={'userPassword'}
                      innerRef={node => (this.password = node)}
                      namevalid="passwordValid"
                      value={user.password || ''}
                      disabled={!!user._id}
                      onChange={event =>
                        this.onChangeUser(
                          null,
                          'password',
                          'passwordValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              {/* Supervisor Data */}
              <Row
                className={`${
                  _.find(user.roles, r => r === 'supervisor') ? '' : 'd-none'
                }`}
              >
                <Col xs={12}>
                  <h6>
                    <Trans>Supervisor Information</Trans>
                  </h6>
                  <hr />
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.jobTitleValid
                    }
                  >
                    <Label>
                      <Trans>Job Title</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.jobTitle = node)}
                      namevalid="jobTitleValid"
                      value={user.supervisor.job_title || ''}
                      onChange={event =>
                        this.onChangeUser(
                          'supervisor',
                          'job_title',
                          'jobTitleValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              {/* Pro Data */}
              <Row
                className={`${
                  _.find(user.roles, r => r === 'pro') ? '' : 'd-none'
                }`}
              >
                <Col xs={12}>
                  <h6>
                    <Trans>Proffesional Information</Trans>
                  </h6>
                  <hr />
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' +
                      this.state.specialitiesValid
                    }
                  >
                    <Label>
                      <Trans>Specialities</Trans>
                    </Label>
                    <Select
                      className="primary"
                      multi={true}
                      closeOnSelect={false}
                      options={specialities.map(p => ({
                        label: t(p),
                        value: p
                      }))}
                      value={user.pro.specialities}
                      onChange={event =>
                        this.onChangeUser(
                          'pro',
                          'specialities',
                          'specialitiesValid',
                          event.map(e => e.value),
                          !!event.length
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.skillsValid
                    }
                  >
                    <Label>
                      <Trans>Skills</Trans>
                    </Label>
                    <Select
                      className="primary"
                      multi={true}
                      closeOnSelect={false}
                      options={skills.map(p => ({
                        label: t(p),
                        value: p
                      }))}
                      value={user.pro.skills}
                      onChange={event =>
                        this.onChangeUser(
                          'pro',
                          'skills',
                          'skillsValid',
                          event.map(e => e.value),
                          !!event.length
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' +
                      this.state.delivery_addressValid
                    }
                  >
                    <Label>
                      <Trans>Delivery Address</Trans>
                    </Label>
                    <GoogleMapLibrary>
                      <PlacesAutocomplete
                        value={
                          user.pro.delivery_address
                            ? user.pro.delivery_address
                            : ''
                        }
                        onChange={delivery_address =>
                          this.onChangeUser(
                            'pro',
                            'delivery_address',
                            'delivery_addressValid',
                            delivery_address,
                            true
                          )
                        }
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps
                        }) => (
                          <div>
                            <input
                              ref={node => (this.delivery_address = node)}
                              {...getInputProps({
                                className: 'form-control',
                                namevalid: 'delivery_addressValid'
                              })}
                            />
                            <ul
                              className={
                                'dropdown-menu inner ' +
                                (suggestions.length === 0 ? '' : 'show')
                              }
                            >
                              {suggestions.map(suggestion => (
                                <li {...getSuggestionItemProps(suggestion)}>
                                  <a href="/#" className="dropdown-item">
                                    <span className="text">
                                      {suggestion.description}
                                    </span>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </GoogleMapLibrary>
                  </FormGroup>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' +
                      this.state.descriptionValid
                    }
                  >
                    <Label>
                      <Trans>Description</Trans>
                    </Label>
                    <Input
                      type="textarea"
                      innerRef={node => (this.description = node)}
                      namevalid="descriptionValid"
                      value={user.pro.description || ''}
                      onChange={event =>
                        this.onChangeUser(
                          'pro',
                          'description',
                          'descriptionValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>

                <Col xs={12}>
                  <h6>
                    <Trans>Pro Company Information</Trans>
                  </h6>
                  <hr />
                </Col>
                <Col xs={12} md={4}>
                  <Col
                    xs={12}
                    className={'display-flex justify-content-center'}
                  >
                    <div
                      className="avatar-image"
                      style={{
                        backgroundImage: `url('${_.get(
                          user,
                          'pro.logo',
                          defaultAvatar
                        )}')`
                      }}
                    />
                  </Col>
                  <Col xs={12} className={'justify-content-center text-center'}>
                    <Button
                      color={'info'}
                      round="true"
                      onClick={() => this.handleClickLogo()}
                    >
                      <Trans>Change</Trans>
                    </Button>
                  </Col>
                </Col>
                <Col xs={12} md={8}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' +
                      this.state.commercialNameValid
                    }
                  >
                    <Label>
                      <Trans>Commercial Name</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.commercialName = node)}
                      namevalid="commercialNameValid"
                      value={_.get(user.pro, 'commercial_name', '')}
                      onChange={event =>
                        this.onChangeUser(
                          'pro',
                          'commercial_name',
                          'commercialNameValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' +
                      this.state.commercialStatusValid
                    }
                  >
                    <Label>
                      <Trans>Commercial Status</Trans>
                    </Label>
                    <Select
                      className="primary"
                      value={_.get(user.pro, 'commercial_status', null)}
                      options={businessTypes.map(p => ({
                        label: t(p),
                        value: p
                      }))}
                      onChange={event => {
                        this.onChangeUser(
                          'pro',
                          'commercial_status',
                          'commercialStatusValid',
                          event ? event.value : null,
                          true
                        );
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.siretValid
                    }
                  >
                    <Label>
                      <Trans>Siret</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.siret = node)}
                      namevalid="siretValid"
                      value={user.pro.siret || ''}
                      onChange={event =>
                        this.onChangeUser(
                          'pro',
                          'siret',
                          'siretValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.sirenValid
                    }
                  >
                    <Label>
                      <Trans>Siren</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.siren = node)}
                      namevalid="sirenValid"
                      value={user.pro.siren || ''}
                      onChange={event =>
                        this.onChangeUser(
                          'pro',
                          'siren',
                          'sirenValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.managerValid
                    }
                  >
                    <Label>
                      <Trans>Manager</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.manager = node)}
                      namevalid="managerValid"
                      value={user.pro.manager || ''}
                      onChange={event =>
                        this.onChangeUser(
                          'pro',
                          'manager',
                          'managerValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              {/* Seller Data */}
              <Row
                className={`${
                  _.find(user.roles, r => r === 'seller') ? '' : 'd-none'
                }`}
              >
                <Col xs={12}>
                  <h6>
                    <Trans>Seller Information</Trans>
                  </h6>
                  <hr />
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.providerValid
                    }
                  >
                    <Label>
                      <Trans>Provider</Trans>
                    </Label>
                    <Select
                      className="primary"
                      clearable={false}
                      options={providers.map(p => ({
                        label: p.name,
                        value: p._id
                      }))}
                      value={user.seller.provider}
                      required={true}
                      disabled={user._id && user.seller.provider ? true : false}
                      onChange={event => {
                        this.onChangeUser(
                          'seller',
                          'provider',
                          'providerValid',
                          event ? event.value : null,
                          !!event
                        );
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              {/* Client Data */}
              <Row
                className={`${
                  _.find(user.roles, r => r === 'client') ? '' : 'd-none'
                }`}
              >
                <Col xs={12}>
                  <h6>
                    <Trans>Client Information</Trans>
                  </h6>
                  <hr />
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' +
                      this.state.firstnameValid
                    }
                  >
                    <Label>
                      <Trans>First Name</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.firstname = node)}
                      namevalid="firstnameValid"
                      value={user.client.firstname || ''}
                      required="required"
                      onChange={event =>
                        this.onChangeUser(
                          'client',
                          'firstname',
                          'firstnameValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.originValid
                    }
                  >
                    <Label>
                      <Trans>Origin</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={clientOrigin.map(c => ({
                        label: t(c),
                        value: c
                      }))}
                      value={user.client.origin}
                      onChange={event =>
                        this.onChangeUser(
                          'client',
                          'origin',
                          'originValid',
                          event ? event.value : null,
                          !!event
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.lastnameValid
                    }
                  >
                    <Label>
                      <Trans>Last Name</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.lastname = node)}
                      namevalid="lastnameValid"
                      value={user.client.lastname || ''}
                      onChange={event =>
                        this.onChangeUser(
                          'client',
                          'lastname',
                          'lastnameValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.props.onCancel()}>
            <Trans>Close</Trans>
          </Button>
          <Button color="info" onClick={() => this.onSaveUser()}>
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>

        {showGallery ? (
          <Gallery
            type={'profile'}
            selectImage={url =>
              this.setState(prevState => {
                return {
                  ...prevState,
                  showGallery: false,
                  user: {
                    ...prevState.user,
                    photoURL: url
                  },
                  file: null
                };
              })
            }
            closeGallery={() =>
              this.setState(prevState => {
                return { ...prevState, showGallery: false };
              })
            }
          />
        ) : null}
      </Modal>
    );
  }
}

export default connect(state => state)(translate('translations-fr')(UserModal));
