import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import {
  AlertConfirm,
  PaginationCustom,
  PanelHeader,
  Spinner
} from '../../../components';
import { all, remove } from '../../../helpers/actions/users';
import UserCard from './UserCard';
import UserModal from './UserModal';
import { roles } from '../../../helpers/nomenclators';
import Select from 'react-select';
import { auth } from 'firebase';
import { notify } from '../../../helpers/actions/index';

class Users extends Component {
  state = {
    loading: true,
    users: [],
    count: 0,
    page: 1,
    limit: 18,
    search: '',
    role: null,
    user: null,
    userDelete: null
  };

  load() {
    this.setState({ loading: true });

    let { page, limit, search, role } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(all({ limit, offset, search, role }))
      .then(({ data, meta }) => {
        this.setState({ users: data, ...meta, loading: false });
      })
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    this.load();
  }

  filter(name, value) {
    this.setState({ [name]: value, page: 1 }, () => this.load());
  }

  deleteUser(id) {
    this.setState({ loading: true, userDelete: null });

    let { dispatch } = this.props;

    dispatch(remove(id))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  resetPassword(email = '') {
    if (email !== '') {
      auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          this.props.dispatch(
            notify(
              'info',
              'Email to reset password has been sent to user email: ' + email
            )
          );
        })
        .catch(err => {
          this.props.dispatch(notify('danger', 'Error to send email'));
        });
    } else {
      this.props.dispatch(
        notify('danger', "The user doesn't have an email address")
      );
    }
  }

  render() {
    let { loading, users, page, limit, count, user, userDelete } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col xs={12} md={4}>
                    <FormGroup>
                      <Label>
                        <Trans>Search</Trans>
                      </Label>
                      <Input
                        type="text"
                        value={this.state.search}
                        onChange={event =>
                          this.filter('search', event.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={3}>
                    <FormGroup>
                      <Label>
                        <Trans>Role</Trans>
                      </Label>
                      <Select
                        className="primary"
                        options={roles.map(c => ({
                          label: <Trans>{c}</Trans>,
                          value: c
                        }))}
                        multi={false}
                        closeOnSelect={true}
                        value={this.state.role}
                        onChange={event =>
                          this.filter(
                            'role',
                            event && event.value ? event.value : ''
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col className={'text-right'} xs={12} md={5}>
                    <Button
                      color="info"
                      onClick={() => this.setState({ user: {} })}
                    >
                      <Trans>Add</Trans>
                    </Button>
                    <Button
                      className={'ml-2'}
                      color={'default'}
                      onClick={() => this.load()}
                    >
                      <i className="now-ui-icons arrows-1_refresh-69" />
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          {users.length > 0 ? (
            <div>
              <Row>
                {users.map((u, key) => (
                  <Col key={key} xs={12} md={4}>
                    <UserCard
                      userData={u}
                      onEdit={() => this.setState({ user: u })}
                      onDelete={() => this.setState({ userDelete: u._id })}
                      onResetPassword={() => this.resetPassword(u.email || '')}
                    />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col xs={12} className={'text-center'}>
                  <PaginationCustom
                    className="display-flex justify-content-center"
                    page={page}
                    count={count}
                    maxPerPage={limit}
                    onPageChange={page =>
                      this.setState({ page }, () => this.load())
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CardBody>
                    <p className={'text-not-found text-center'}>
                      <Trans>No users found</Trans>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {!!user ? (
            <UserModal
              show={!!user}
              userData={user}
              dispatch={this.props.dispatch}
              onCancel={() => this.setState({ user: null })}
              onConfirm={() => this.setState({ user: null }, () => this.load())}
            />
          ) : null}

          {!userDelete ? null : (
            <AlertConfirm
              message={'The user cannot be recovered'}
              onCancel={() => this.setState({ userDelete: null })}
              onConfirm={() => this.deleteUser(userDelete)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect(state => state)(translate('translations-fr')(Users));
